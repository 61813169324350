import AxiosService from "@/services/AxiosService";

class AddService {
  async getCountries() {
    return await AxiosService.get("/v1/countries", {})
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async getCities(params) {
    return await AxiosService.get(`/v1/cities`, {params})
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  async getAddParams(params) {
    return await AxiosService.get(`/v1/prices/`, {params})
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  async getLanguages() {
    return await AxiosService.get(`/v1/languages`, {})
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  async isEnabledFeatureFlag() {
    return await AxiosService.get(`/v1/get-enabled-feature-flags`, {})
      .then((resp) => {
        return resp.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}
export default new AddService();
